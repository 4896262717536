import { Outlet } from "react-router-dom";

import {
  useEligibleProductsForInvitation,
  useMarketplaceRegistrations,
} from "@mc/features/Onboarding";
import { FullScreenLayout } from "@mc/layouts";

import { DefaultPathRedirect } from "../redirects";

/**
 * Renders child routes, if the user has a pending onboarding
 * or eligible products for invitation, otherwise redirects to
 * the user's current default path.
 */
export const OnboardingOutlet = () => {
  const { hasPendingOnboardings } = useMarketplaceRegistrations();
  const { hasProductsForInvitation } = useEligibleProductsForInvitation();

  const isOnboardingComplete =
    !hasPendingOnboardings && !hasProductsForInvitation;

  if (isOnboardingComplete) return <DefaultPathRedirect />;

  return (
    <FullScreenLayout>
      <Outlet />
    </FullScreenLayout>
  );
};
