import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  MarketplaceRegistrationFilter,
  OnboardingRequestStatus,
} from "@m/api/public/types";

import { SUPPORTED_ONBOARDING_SKUS } from "@mc/constants";

export const GET_EXTERNAL_ID = gql(/* GraphQL */ `
  query getExternalId($where: MarketplaceRegistrationFilter) {
    userProfile {
      id
      currentCompany {
        id
        marketplaceRegistrations(where: $where) {
          edges {
            node {
              iamExternalId
              sku
              id
            }
          }
        }
      }
    }
  }
`);

export const useIamExternalId = (awsAccountId: string = null) => {
  const where: MarketplaceRegistrationFilter = {
    onboardingStatus: OnboardingRequestStatus.Complete,
  };

  if (awsAccountId) where.awsAccountId = awsAccountId;

  const { data, ...result } = useQuery(GET_EXTERNAL_ID, {
    variables: { where },
  });

  const completedOnboardings =
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges.map(
      ({ node }) => node
    ) || [];

  const supportedMarketplaceRegistrations = completedOnboardings.filter(
    ({ sku }) => SUPPORTED_ONBOARDING_SKUS.includes(sku)
  );

  const validExternalIds = supportedMarketplaceRegistrations.map(
    (onboarding) => onboarding.iamExternalId
  );

  // todo(djbowers): how do we pick the correct iamExternalId if there are multiple completed onboardings?
  const iamExternalId = validExternalIds?.[0] ?? null;

  return { data: iamExternalId, ...result } as const;
};
