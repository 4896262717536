import { Outlet } from "react-router-dom";

import { useEligibleProductsForInvitation } from "@mc/features/Onboarding";

import { OnboardingPathRedirect } from "../redirects";

/** Renders child routes, if the user has eligible products for invitation. */
export const EligibleProductsForInvitationOutlet = () => {
  const { hasProductsForInvitation } = useEligibleProductsForInvitation();

  if (!hasProductsForInvitation) return <OnboardingPathRedirect />;

  return <Outlet />;
};
