import { useParams } from "react-router-dom";

import { SpinnerScreen, useBreadcrumbs } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { FEATURE_FLAGS, PATHS } from "@mc/constants";
import {
  CLOUD_SCORE_BREADCRUMBS,
  CloudScorePillarIds,
  PILLAR_IDS,
} from "@mc/features/CloudScore/constants";
import { NotFound } from "@mc/pages";
import { createCompanyPath } from "@mc/utils/companyPath";

import { useCloudScoreDetails } from "../api";
import {
  CloudScoreCheckList,
  CloudScoreConfigurationError,
  CloudScoreRecommendationList,
  PillarDetailsCards,
  ScorecardTabs,
  ScorecardTrendGraph,
  TalkToExpertBanner,
} from "../components";

export const CloudScoreDetailsPage = () => {
  const {
    data: { summaries },
    loading,
  } = useCloudScoreDetails();

  const { pillarId } = useParams<{ pillarId: CloudScorePillarIds }>();
  const breadcrumbs = useBreadcrumbs(
    createCompanyPath(PATHS.CLOUD_SCORE_PILLAR_DETAILS),
    CLOUD_SCORE_BREADCRUMBS
  );

  const detailsAvailable = summaries.length > 0;

  if (!PILLAR_IDS.includes(pillarId)) return <NotFound />;

  return (
    <>
      <Page
        data-testid="cloud-score-details-page"
        title={
          <PageTitle
            breadcrumbs={breadcrumbs}
            tabs={
              detailsAvailable ? <ScorecardTabs summaries={summaries} /> : false
            }
            padding="small"
          />
        }
      >
        {!loading && detailsAvailable && (
          <div className="flex flex-col gap-y-3">
            <AccessRequired
              children={null}
              fallback={<TalkToExpertBanner />}
              feature={FEATURE_FLAGS.SCORECARD_RECOMMENDATIONS}
            />

            <ScorecardTrendGraph className="pb-3" pillarId={pillarId} />

            <PillarDetailsCards pillarId={pillarId} summaries={summaries} />

            <AccessRequired feature={FEATURE_FLAGS.SCORECARD_RECOMMENDATIONS}>
              <CloudScoreRecommendationList pillarId={pillarId} />
            </AccessRequired>

            <CloudScoreCheckList pillarId={pillarId} />
          </div>
        )}

        {!loading && !detailsAvailable && <CloudScoreConfigurationError />}

        {loading && <SpinnerScreen fitToParent={true} />}
      </Page>
    </>
  );
};
