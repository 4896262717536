import { PropsWithChildren, ReactNode } from "react";

import { PageTitle } from "@mc/components/PageTitle";

interface Props extends PropsWithChildren {
  navigation: ReactNode;
  title: string;
}

export const NavigationLayout = ({ children, navigation, title }: Props) => (
  <div className="h-screen">
    {title && <PageTitle border="none" padding="small" title={title} />}
    <div className="flex">
      <div className="min-w-[190px] px-3 py-4">{navigation}</div>
      <div className="flex grow flex-col overflow-y-auto">{children}</div>
    </div>
  </div>
);
