import { useState } from "react";

import { CasePriority } from "@m/api/public/types";
import { ButtonRadioGroup, Field } from "@m/ui";

import { CASE_PRIORITY_DEFINITIONS } from "../constants";
import { CaseType } from "../types";

export const PriorityRadioGroup = ({ caseType }: { caseType: CaseType }) => {
  const priorities = CASE_PRIORITY_DEFINITIONS[caseType];

  const [priority, setPriority] = useState<CasePriority>(priorities[0].value);

  const handleChange = (priority: CasePriority) => {
    setPriority(priority);
  };

  if (caseType === CaseType.AwsGuidance) {
    // For AWS Guidance cases, we will use the default priority of 'Medium', so there is no need to render the option buttons here
    return null;
  }

  return (
    <Field htmlFor="priority" label="Priority" row="2/3">
      <ButtonRadioGroup
        aria-label="Priority"
        className="w-full basis-2/3"
        id="priority"
        name="priorityRadio"
        onChange={handleChange}
        options={priorities.map(({ name, value }) => ({ label: name, value }))}
        value={priority}
        width="fill-even"
      />
    </Field>
  );
};
