import { useAccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, PATHS, ROLES } from "@mc/constants";
import { DevOpsDeskPopup } from "@mc/features/DevOpsDesk/components";
import { useEngagementsList } from "@mc/features/Engagements/api";

import { SidebarGroup } from "./SidebarGroup";
import { SidebarLink } from "./SidebarLink";

export const EngagementsGroup = () => {
  const isEngagementsEnabled = useAccessRequired({
    feature: FEATURE_FLAGS.ENGAGEMENTS,
  });
  const isDevOpsDeskEnabled = useAccessRequired({
    feature: FEATURE_FLAGS.DEVOPS_DESK,
  });
  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });

  const {
    data: { engagements },
    loading,
  } = useEngagementsList();

  const hasEngagements = engagements.length > 0;

  const shouldShowProjectsLink =
    isEngagementsEnabled && (hasEngagements || isEngagementManager);

  const shouldShowEngagementsGroup =
    shouldShowProjectsLink || isDevOpsDeskEnabled;

  if (!shouldShowEngagementsGroup) return null;

  return (
    <SidebarGroup title="Engagements">
      {shouldShowProjectsLink && (
        <SidebarLink
          count={hasEngagements ? engagements.length : undefined}
          loading={loading}
          title="Projects"
          to={PATHS.ENGAGEMENTS_PROJECTS}
        />
      )}

      <SidebarLink
        feature={FEATURE_FLAGS.DEVOPS_DESK}
        isNew={true}
        popupContent={<DevOpsDeskPopup />}
        title="Engineer Assist"
        to={PATHS.ENGAGEMENTS_ENGINEER_ASSIST}
      />
    </SidebarGroup>
  );
};
