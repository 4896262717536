import { RadioGroup } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

import { Badge, Field, Tooltip } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { CircleCheckbox } from "@mc/components/CircleCheckbox";
import { FEATURE_FLAGS, SKUS } from "@mc/constants";

import { CaseType } from "../types";

interface Props {
  caseType: CaseType;
  onChange: (caseType: CaseType) => void;
}

export const CaseTypeRadioGroup = ({ caseType, onChange }: Props) => {
  return (
    <Field label="Case Type" row="2/3" rowAlign="top">
      <RadioGroup
        value={caseType}
        onChange={onChange}
        className="grid grid-cols-2 gap-1"
      >
        <Option value={CaseType.Account} label="Account & Billing" />
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_OPERATIONAL_SUPPORT}
        >
          <Option value={CaseType.Operations} label="Operations Support" />
        </AccessRequired>
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_TECHNICAL_SUPPORT}
        >
          <Option value={CaseType.Support} label="Technical Support" />
        </AccessRequired>
        <AccessRequired entitlement={SKUS.CLOUD_GATEWAY}>
          <Option value={CaseType.AwsGuidance} label="AWS Guidance" />
        </AccessRequired>
      </RadioGroup>
    </Field>
  );
};

interface OptionsProps {
  label: string;
  popupContent?: ReactNode;
  showNew?: boolean;
  value: CaseType;
}

const Option = ({ value, label, showNew, popupContent }: OptionsProps) => {
  return (
    <RadioGroup.Option
      value={value}
      className="form-control__focus-within w-full rounded-md"
    >
      {({ checked }) => (
        <button
          type="button"
          className={clsx(
            "flex h-full w-full items-center gap-1.5 rounded-md border p-2 text-left",
            {
              "text-default": !checked,
              "border-blue-500 bg-blue-50 text-action": checked,
            }
          )}
        >
          <CircleCheckbox checked={checked} size="small" />
          <div className="flex grow flex-wrap gap-0.5">
            <div className="text-[13px] font-semibold">{label}</div>
            {showNew && (
              <div className="flex items-center">
                <Badge label="New" status="positive" strong size="small" />
              </div>
            )}
          </div>
          {popupContent && (
            <Tooltip
              asChild
              className="p-2"
              color="light"
              content={popupContent}
              side="right"
            >
              <InformationCircleIcon className="h-2 w-2 shrink-0 text-accent hover:cursor-help" />
            </Tooltip>
          )}
        </button>
      )}
    </RadioGroup.Option>
  );
};
