import { Outlet, RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { BillingSettingsPage } from "@mc/features/BillingSettings";
import { MarketplaceSubscriptionsPage } from "@mc/features/MarketplaceSubscriptions";
import { NotificationSettingsPage } from "@mc/features/Notifications";
import { SettingsNavigation } from "@mc/features/Settings";
import { UserManagementPage } from "@mc/features/UserManagement";
import { UserProfilePage } from "@mc/features/UserProfile";
import { NavigationLayout } from "@mc/layouts";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

import { accountsRoutes } from "./accountsRoutes";

export const settingsRoutes: RouteObject[] = [
  {
    element: (
      <NavigationLayout navigation={<SettingsNavigation />} title="Settings">
        <Outlet />
      </NavigationLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={PATHS.SETTINGS_PROFILE} />,
      },
      {
        path: "profile",
        element: <UserProfilePage />,
      },
      {
        path: "users",
        element: <UserManagementPage />,
      },
      {
        path: "accounts",
        children: accountsRoutes,
      },
      {
        path: "notifications",
        element: <NotificationSettingsPage />,
      },
      {
        path: "billing",
        element: <BillingSettingsPage />,
      },
      {
        path: "subscriptions",
        element: <MarketplaceSubscriptionsPage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];
