import { SKUS } from "@mc/constants";
import { SubscriptionDetailsCard } from "@mc/features/MarketplaceSubscriptions/components";

import {
  CloudEngagementsOnboardingWelcomeDetails,
  CloudGatewayOnboardingWelcomeDetails,
  CloudScoreOnboardingWelcomeDetails,
} from "../components";
import { WelcomePageArtboard } from "../icons";
import { useMarketplaceRegistrations } from "../providers";

export const OnboardingWelcomePage = () => {
  const { pendingOnboardings } = useMarketplaceRegistrations();
  const pendingOnboarding = pendingOnboardings?.[0]; // todo(djbowers): update for multiple marketplace offerings

  return (
    <div
      className="flex flex-col items-center justify-center gap-4"
      data-testid="onboarding-cloud-score-welcome-page"
    >
      <WelcomePageArtboard />

      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-center text-4xl font-bold text-default">
          Say hello to Mission Control
        </div>
        <div className="max-w-sm text-center text-xl font-normal text-subdued">
          Thank you for signing up!
        </div>
      </div>

      <div className="flex w-full max-w-sm flex-col items-center justify-center gap-5">
        <SubscriptionDetailsCard
          expiresAt={pendingOnboarding.expirationTime}
          iamExternalId={pendingOnboarding.iamExternalId}
          productDisplayName={pendingOnboarding.catalogItem.displayName}
          registrationStatus={pendingOnboarding.registrationStatus}
          subscribedAccountId={pendingOnboarding.awsAccountId}
          subscribedAt={pendingOnboarding.subscribeTime}
        />

        <WelcomeDetails sku={pendingOnboarding.sku} />
      </div>
    </div>
  );
};

const WelcomeDetails = ({ sku }) => {
  const { CLOUD_SCORE, CLOUD_GATEWAY, CLOUD_ENGAGEMENTS } = SKUS;

  switch (sku) {
    case CLOUD_SCORE:
      return <CloudScoreOnboardingWelcomeDetails />;
    case CLOUD_GATEWAY:
      return <CloudGatewayOnboardingWelcomeDetails />;
    case CLOUD_ENGAGEMENTS:
      return <CloudEngagementsOnboardingWelcomeDetails />;
    default:
      return null;
  }
};
