import { ArrowRightIcon, CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { Button } from "@m/ui";

import { PATHS } from "@mc/constants";
import { EmptyCircleIcon } from "@mc/icons";

import { usePendingCloudGatewayOnboarding } from "../hooks";

export const CloudGatewayOnboardingWelcomeDetails = () => {
  const pendingOnboarding = usePendingCloudGatewayOnboarding();

  const payerIdentified = pendingOnboarding?.payerAccountId;
  const startPath = payerIdentified
    ? PATHS.ONBOARDING_CLOUD_GATEWAY_CONNECT_PAYER
    : PATHS.ONBOARDING_CLOUD_GATEWAY_IDENTIFY_PAYER;

  return (
    <>
      <CloudGatewayChecklist payerIdentified={payerIdentified} />

      <Button kind="primary" className="w-full py-2" to={startPath}>
        Let's get started
        <ArrowRightIcon className="h-2 w-2" />
      </Button>
    </>
  );
};

const CloudGatewayChecklist = ({ payerIdentified }) => (
  <div>
    <div className="mb-4 max-w-sm text-center text-xl font-normal text-subdued">
      There are a couple more steps to do first
    </div>
    <div className="inline-flex w-full gap-2 rounded-t-md border border-b-0 bg-gray-50 p-2 font-semibold text-subdued">
      <CheckIcon className="my-auto h-2 w-2 stroke-2 text-status-good" />
      Create Your Account
    </div>
    <div
      className={clsx(
        "inline-flex w-full gap-2 border border-b-0 p-2 font-semibold",
        { "bg-gray-50 text-subdued": payerIdentified }
      )}
    >
      {payerIdentified ? (
        <CheckIcon className="my-auto h-2 w-2 stroke-2 text-status-good" />
      ) : (
        <EmptyCircleIcon className="my-auto h-2 w-2 stroke-2 text-accent" />
      )}
      Identify AWS Payer Account
    </div>
    <div className="inline-flex w-full gap-2 rounded-b-md border p-2 font-semibold ">
      <EmptyCircleIcon className="my-auto h-2 w-2 stroke-2 text-accent" />
      Connect AWS Payer Account
    </div>
  </div>
);
