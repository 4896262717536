import { Outlet } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { usePendingCloudGatewayOnboarding } from "@mc/features/Onboarding/hooks";
import { Navigate } from "@mc/router";

/**
 * Redirects to the start of onboarding if the AWS payer account has
 * not been successfully identified for the ongoing Cloud Gateway onboarding;
 * otherwise, it renders child routes.
 */
export const ConnectAwsPayerOutlet = () => {
  const pendingCloudGatewayOnboarding = usePendingCloudGatewayOnboarding();
  const payerIdentifed = pendingCloudGatewayOnboarding?.payerAccountId;

  if (!payerIdentifed)
    return <Navigate replace to={PATHS.ONBOARDING_WELCOME} />;

  return <Outlet />;
};
