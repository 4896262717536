import { FormEventHandler, useMemo, useState } from "react";
import { generatePath, useParams } from "react-router-dom";

import {
  CaseCategory,
  CasePriority,
  CaseSubcategory,
} from "@m/api/public/types";
import {
  Button,
  Buttons,
  DynamicSelect,
  Field,
  Fields,
  Input,
  Textarea,
} from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { MUTATIONS, PATHS } from "@mc/constants";
import { useAwsAccounts } from "@mc/features/Accounts/api";
import { AccessDenied } from "@mc/pages";
import { useNavigate } from "@mc/router";

import { useCreateCase } from "../api";
import {
  CaseTypeRadioGroup,
  CategorySelector,
  PriorityRadioGroup,
} from "../components";
import {
  CASE_PRIORITY_DEFINITIONS,
  NEW_CASE_FORM_AWS_REGIONS,
} from "../constants";
import { CaseType } from "../types";
import { createCaseDescription } from "../utils";

export const CreateCasePage = () => {
  const navigate = useNavigate();

  const { caseType = CaseType.Account } = useParams<{ caseType?: CaseType }>();

  const {
    data: { accounts },
    loading,
  } = useAwsAccounts();

  const [createCase, { loading: createCaseLoading }] = useCreateCase();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);

  const accountOptions = useMemo(
    () =>
      accounts.map(({ databaseId, name }) => ({
        label: name,
        subLabel: databaseId,
        value: databaseId,
      })),
    [accounts]
  );

  const regionOptions = NEW_CASE_FORM_AWS_REGIONS.map((region) => ({
    label: region,
    value: region,
  }));

  const handleSubmit: FormEventHandler<CreateCaseForm> = (e) => {
    e.preventDefault();

    const {
      titleInput,
      descriptionInput,
      categorySelect,
      subcategorySelect,
      priorityRadio,
      resourcesInput,
    } = e.currentTarget.elements;

    const categoryValues = categorySelect?.value?.split(":");

    if (!categoryValues) return;

    let category = categoryValues[0];
    let subcategory = categoryValues[1];

    if (!subcategory && subcategorySelect?.value) {
      subcategory = subcategorySelect.value;
    }

    const descriptionText = descriptionInput.value;
    const categoryText =
      categorySelect.options?.[categorySelect.selectedIndex].text;
    const subcategoryText =
      subcategorySelect?.options?.[subcategorySelect?.selectedIndex]?.text;

    if (subcategoryText === "Visualization and Reporting") {
      // ServiceNow cases with the subcategory 'Visualization and Reporting' call for the 'CMP Pool' category. Our current mapping approach applies one CaseCategory to all
      // subcategories (see apps/mission-control/src/features/CustomerCases/constants/categoryDefinitions.ts).
      //
      // Since this is the only example of this currently, we’ll handle it directly here. Future similar scenarios may need a more flexible mapping solution.
      category = CaseCategory.CmpPool;
    }

    let priorityText;
    let priorityValue;
    if (caseType === CaseType.AwsGuidance) {
      // The awsGuidance case type defaults to 'Medium'
      const awsGuidancePriorityDefinition = CASE_PRIORITY_DEFINITIONS[
        caseType
      ].find(({ value }) => value === CasePriority.Moderate);
      priorityText = awsGuidancePriorityDefinition.name;
      priorityValue = awsGuidancePriorityDefinition.value;
    } else {
      const selectedPriorityDefinition = CASE_PRIORITY_DEFINITIONS[
        caseType
      ].find(({ value }) => value === priorityRadio.value);
      priorityText = selectedPriorityDefinition.name;
      priorityValue = selectedPriorityDefinition.value;
    }

    const accountIds = selectedAccounts
      .map((account) => account.value)
      .join(", ");
    const regions = selectedRegions.map((region) => region.value).join(", ");
    const resourcesText = resourcesInput.value;

    const description = createCaseDescription({
      description: descriptionText,
      category: categoryText,
      subcategory: subcategoryText,
      priority: priorityText,
      accountIds,
      regions,
      resourcesText,
      caseType,
    });

    createCase({
      description,
      shortDescription: titleInput.value,
      priority: priorityValue,
      category: category as CaseCategory,
      ...(subcategory && { subcategory: subcategory as CaseSubcategory }),
    });
  };

  const handleChangeCaseType = (value: CaseType) =>
    navigate(
      generatePath(PATHS.SUPPORT_CASES_CREATE_CASETYPE, { caseType: value })
    );

  const handleClickCancel = () => navigate(PATHS.SUPPORT_CASES);

  return (
    <AccessRequired
      mutation={MUTATIONS.CREATE_CASE}
      fallback={<AccessDenied />}
    >
      <Page
        data-testid="create-case-page"
        title={<PageTitle title="New Case" parentTitle="Cases" />}
        width="small"
        className="min-w-[576px]"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-[40px]">
          <Fields>
            <Textarea
              aria-label="Title"
              autoFocus={true}
              autogrow
              containerClasses="text-2xl"
              id="title"
              name="titleInput"
              nextFieldOnReturn={true}
              noStyles
              onChange={(e) => setTitle(e.currentTarget.value)}
              placeholder="Case title"
              required={true}
              rows={1}
              value={title}
            />
            <Textarea
              aria-label="Description"
              autogrow
              id="description"
              name="descriptionInput"
              noStyles
              onChange={(e) => setDescription(e.currentTarget.value)}
              placeholder="Add case description including any technologies and AWS services that will be used"
              required={true}
              rows={3}
              value={description}
            />
          </Fields>

          <hr />

          <CaseTypeRadioGroup
            caseType={caseType}
            onChange={handleChangeCaseType}
          />

          <CategorySelector caseType={caseType} />
          <PriorityRadioGroup caseType={caseType} />

          <hr />
          <Field
            htmlFor="accounts"
            label="Accounts"
            row="2/3"
            rowAlign="top"
            className="w-full"
          >
            <DynamicSelect
              id="accounts"
              name="accountsSelect"
              aria-label="Accounts Select"
              placeholder="Add accounts"
              initialOptions={accountOptions}
              selectedOptions={selectedAccounts}
              isMulti={true}
              isLoading={loading}
              onSelectedOptionsChange={setSelectedAccounts}
            />
          </Field>
          <Field label="Regions" row="2/3" rowAlign="top" className="w-full">
            <DynamicSelect
              id="regions"
              name="regionsSelect"
              aria-label="Regions Select"
              placeholder="Add regions"
              isMulti={true}
              isLoading={loading}
              initialOptions={regionOptions}
              selectedOptions={selectedRegions}
              onSelectedOptionsChange={setSelectedRegions}
            />
          </Field>
          <Field
            label="Resources"
            htmlFor="resources"
            row="2/3"
            rowAlign="top"
            className="w-full"
          >
            <Input
              aria-label="Resources"
              className="border-none"
              id="resources"
              name="resourcesInput"
              placeholder="Add resource IDs"
              type="text"
            />
          </Field>

          <Buttons>
            <Button kind="primary" loading={createCaseLoading} type="submit">
              Create case
            </Button>
            <Button kind="primary" fill="none" onClick={handleClickCancel}>
              Cancel
            </Button>
          </Buttons>
        </form>
      </Page>
    </AccessRequired>
  );
};

export interface CreateCaseFormElements extends HTMLFormControlsCollection {
  titleInput: HTMLInputElement;
  descriptionInput: HTMLInputElement;
  categorySelect?: HTMLSelectElement;
  subcategorySelect?: HTMLSelectElement;
  priorityRadio?: HTMLInputElement;
  resourcesInput: HTMLInputElement;
}

export interface CreateCaseForm extends HTMLFormElement {
  readonly elements: CreateCaseFormElements;
}
