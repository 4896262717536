import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const GET_MISSING_IAM_ROLES = gql(/* GraphQL */ `
  query GetMissingIamRoles {
    userProfile {
      currentCompany {
        marketplaceRegistrations {
          edges {
            node {
              awsAccountId
              missingIamRoles
            }
          }
        }
      }
    }
  }
`);

export const useMissingIamRoles = () => {
  const { data, loading } = useQuery(GET_MISSING_IAM_ROLES);

  const node =
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges?.[0]
      ?.node;

  const { awsAccountId, missingIamRoles } = node || {};

  return {
    data: { awsAccountId, missingIamRoles },
    loading,
  };
};
