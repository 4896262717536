import { ReactNode } from "react";

import { MarketplaceRegistrationStatus } from "@m/api/public/types";
import { Badge, Card, Tooltip } from "@m/ui";
import { formatFullDate } from "@m/utils";

interface Props {
  expiresAt?: string;
  iamExternalId?: string;
  productDisplayName: string;
  registrationStatus: MarketplaceRegistrationStatus;
  subscribedAccountId: string;
  subscribedAt?: string;
}

export const SubscriptionDetailsCard = ({
  expiresAt,
  iamExternalId,
  productDisplayName,
  registrationStatus,
  subscribedAccountId,
  subscribedAt,
}: Props): JSX.Element => {
  const isActive = registrationStatus === MarketplaceRegistrationStatus.Active;

  return (
    <Card className="w-full" data-testid="marketplace-subscription-details">
      <div className="flex flex-col gap-y-1 p-2">
        <div className="flex items-center gap-x-2">
          <div className="text-sm font-bold text-default">
            {productDisplayName}
          </div>
          {isActive && (
            <Badge
              label="Active"
              status="active"
              size="small"
              data-testid="subscription-status-badge"
            />
          )}
        </div>

        <div className="grid grid-cols-2 gap-3">
          <SubscriptionField
            label="AWS Account ID"
            value={subscribedAccountId}
          />
          <div>
            {iamExternalId && (
              <SubscriptionField
                label="IAM External ID"
                value={
                  <Tooltip
                    asChild
                    content={iamExternalId}
                    className="mr-1 max-w-xs break-all p-1"
                    align="start"
                    side="bottom"
                  >
                    <div className="truncate underline decoration-dashed underline-offset-2">
                      {iamExternalId}
                    </div>
                  </Tooltip>
                }
              />
            )}
          </div>
          {subscribedAt && (
            <SubscriptionField
              label="Subscribed"
              value={formatFullDate(subscribedAt)}
            />
          )}
          {expiresAt && (
            <SubscriptionField
              label="Expires"
              value={formatFullDate(expiresAt)}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const SubscriptionField = ({
  label,
  value,
}: {
  label: string;
  value: ReactNode;
}) => (
  <div className="text-xs font-semibold text-default">
    <div className="pb-0.5">{label}</div>
    <div className="text-subdued">{value}</div>
  </div>
);
