import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  ConnectAwsPayerAccountPage,
  IdentifyAwsPayerAccountPage,
} from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

import { ConnectAwsPayerOutlet, IdentifyAwsPayerOutlet } from "./outlets";

export const onboardingCloudGatewayRoutes: RouteObject[] = [
  {
    path: "identify-payer",
    element: <IdentifyAwsPayerOutlet />,
    children: [{ path: "*", element: <IdentifyAwsPayerAccountPage /> }],
  },
  {
    path: "connect-payer",
    element: <ConnectAwsPayerOutlet />,
    children: [{ path: "*", element: <ConnectAwsPayerAccountPage /> }],
  },
  {
    path: "*",
    element: <Navigate replace to={PATHS.ONBOARDING_WELCOME} />,
  },
];
