import { MarketplaceRegistrationType } from "../providers";

/**
 * Find and return the first onboarding that matches a given SKU.
 */
export const findOnboarding = (
  onboardings: MarketplaceRegistrationType[],
  sku: string
): MarketplaceRegistrationType | null => {
  const filteredOnboardings =
    onboardings.filter((onboarding) => onboarding.sku === sku) || [];

  return filteredOnboardings?.[0] || null; // todo(djbowers): is it possible to have more than one onboarding for the same product?
};
