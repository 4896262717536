import { useAuth } from "@m/login";
import { Link, MissionLogo } from "@m/ui";

import { CompanySwitcher } from "@mc/components/CompanySwitcher";
import { CONTACT_US_URL } from "@mc/constants";
import { useIsStaff } from "@mc/hooks";

export const FullScreenLayout = ({ children }) => {
  const isStaff = useIsStaff();
  const { user, logout } = useAuth();

  return (
    <div className="flex h-screen flex-col">
      <div className="m-3">
        {isStaff ? (
          <div className="flex w-fit items-center gap-2">
            <MissionLogo logo="mark" style={{ height: 20 }} color="white" />
            <CompanySwitcher />
          </div>
        ) : (
          <MissionLogo className="h-5 w-5" />
        )}
      </div>

      <div className="grow" />

      <div className="flex grow flex-col items-center overflow-y-auto pb-5">
        {children}
        <div className="mt-5 text-center text-sm font-medium text-default">
          Need help? <Link to={CONTACT_US_URL}>Contact us</Link>
          {user && (
            <>
              {" | "}
              <Link onClick={logout}>Log out</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
