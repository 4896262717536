import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

import { Banner, Link } from "@m/ui";

import { getCloudFormationTemplateURL } from "@mc/utils/cloudFormation";

import { useMissingIamRoles } from "../api";

export const MissingIamRoleBanner = () => {
  const { data, loading } = useMissingIamRoles();
  const { awsAccountId, missingIamRoles } = data;

  if (loading || !missingIamRoles || missingIamRoles.length === 0) {
    return null;
  }

  return (
    <Banner
      data-testid="missing-iam-role-banner"
      className="mb-4"
      status="warning"
      title="Missing Required IAM Roles"
      label={
        <div className="w-full">
          Your AWS account <span className="font-semibold">{awsAccountId}</span>{" "}
          is missing the{" "}
          <span className="font-semibold">
            {formattedRoleList(missingIamRoles)}
          </span>{" "}
          IAM role{missingIamRoles.length > 1 ? "s" : ""}.
        </div>
      }
      actions={
        <Link
          className="w-fit whitespace-nowrap text-end text-yellow-700"
          href={getCloudFormationTemplateURL()}
          target="_blank"
          rightIcon={ArrowUpRightIcon}
        >
          Run Cloud Formation Template
        </Link>
      }
    />
  );
};

function formattedRoleList(missingIamRoles: string[]) {
  if (missingIamRoles.length === 0) {
    return "";
  } else if (missingIamRoles.length === 1) {
    return missingIamRoles[0];
  } else if (missingIamRoles.length >= 2) {
    return missingIamRoles.join(" and ");
  } else {
    const lastElement = missingIamRoles.pop();
    return `${missingIamRoles.join(", ")}, and ${lastElement}`;
  }
}
