import { SUPPORTED_ONBOARDING_SKUS } from "@mc/constants";

import { MarketplaceRegistrationType } from "../providers";

/**
 * Filter marketplace registrations to only those supported by Mission Control.
 */
export const filterSupportedMarketplaceRegistrations = (
  marketplaceRegistrations: MarketplaceRegistrationType[]
) =>
  marketplaceRegistrations.filter(({ sku }) =>
    SUPPORTED_ONBOARDING_SKUS.includes(sku)
  );
