import { fromCamelCaseToProperCase } from "@m/utils";

import { CaseType } from "@mc/features/CustomerCases/types";
import {
  MaintenanceWindowNeeded,
  PreferredContactMethods,
} from "@mc/features/DevOpsDesk/components";

export const createCaseDescription = ({
  description,
  category,
  subcategory,
  priority,
  caseType,
  accountIds,
  regions,
  repositoryUrl,
  resourcesText,
  maintenanceWindowNeeded,
  desiredCompletionDate,
  preferredContactMethod,
  schedulingLink,
}: {
  description: string;
  category: string;
  subcategory: string;
  priority: string;
  caseType: CaseType;
  accountIds?: string;
  regions?: string;
  repositoryUrl?: string;
  resourcesText?: string;
  maintenanceWindowNeeded?: MaintenanceWindowNeeded;
  desiredCompletionDate?: string;
  preferredContactMethod?: PreferredContactMethods;
  schedulingLink?: string;
}) => {
  let header = `Case Type: ${fromCamelCaseToProperCase(caseType)}\n`;

  if (category !== "General Guidance") {
    header += `Case Category: ${category}\n`;
  }

  if (subcategory) {
    header += `Case Subcategory: ${subcategory}\n`;
  }

  header += `Case Priority: ${priority}\n`;

  if (caseType === CaseType.DevOps) {
    header += `Repository URL: ${repositoryUrl || "not reported"}\n`;

    header += `Maintenance Window Needed: ${maintenanceWindowNeeded}\n`;

    header += `Desired Completion Date: ${
      desiredCompletionDate || "not reported"
    }\n`;

    header += `Preferred Contact Method: ${preferredContactMethod}\n`;

    header += `Scheduling Link: ${schedulingLink || "not reported"}\n`;
  }

  header += `Account IDs: ${accountIds || "not reported"}\n`;
  header += `Regions: ${regions || "not reported"}\n`;
  header += `Resources: ${resourcesText || "not reported"}\n`;

  return `${header}\n${description}`;
};
