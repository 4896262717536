import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { DefaultPathRedirect } from "../redirects";

/**
 * Renders child routes, if the user hasn't finished registration,
 * otherwise redirects to the user's current default path.
 */
export const RegistrationOutlet = () => {
  const { user } = useAuth();
  const forceRegistration = user?.isPendingInvite;

  if (!forceRegistration) return <DefaultPathRedirect />;

  return <Outlet />;
};
