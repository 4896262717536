import { useAuth } from "@m/login";

import { MissionControlLogin } from "@mc/app/components";
import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

export const RootPathRedirect = () => {
  const { user } = useAuth();

  if (!user) return <MissionControlLogin />;

  return <Navigate replace to={PATHS.ROOT} />;
};
