import {
  LifebuoyIcon,
  RectangleGroupIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";

import { FEATURE_FLAGS, PATHS } from "@mc/constants";

import { AccessRequired } from "../AccessRequired";

import {
  DropdownLinks,
  EngagementsGroup,
  ResourcesSidebarLinks,
  ScorecardLink,
  SidebarGroup,
  SidebarLink,
  SidebarWrapper,
  TalkToExpertCard,
} from "./components";

export const Sidebar = () => {
  return (
    <SidebarWrapper>
      <SidebarGroup
        feature={[
          FEATURE_FLAGS.DASHBOARD,
          FEATURE_FLAGS.CASES,
          FEATURE_FLAGS.RESOURCES,
        ]}
        matchFeatures="any"
      >
        <SidebarLink
          ariaLabel="Go to Dashboard"
          feature={FEATURE_FLAGS.DASHBOARD}
          icon={Squares2X2Icon}
          title="Dashboard"
          to={PATHS.DASHBOARD}
        />
        <SidebarLink
          ariaLabel="Go to Support Cases"
          feature={FEATURE_FLAGS.CASES}
          icon={LifebuoyIcon}
          title="Cases"
          to={PATHS.SUPPORT}
        />
        <DropdownLinks
          defaultOpen={false}
          feature={FEATURE_FLAGS.RESOURCES}
          icon={RectangleGroupIcon}
          path="/resources/*"
          title="Resources"
        >
          <ResourcesSidebarLinks />
        </DropdownLinks>
      </SidebarGroup>

      <SidebarGroup title="Insights" feature={FEATURE_FLAGS.SCORECARDS}>
        <ScorecardLink />
      </SidebarGroup>

      <AccessRequired
        feature={[FEATURE_FLAGS.ENGAGEMENTS, FEATURE_FLAGS.DEVOPS_DESK]}
        matchFeatures="any"
      >
        <EngagementsGroup />
      </AccessRequired>

      <div className="px-1">
        <AccessRequired feature={FEATURE_FLAGS.SCORECARDS}>
          <AccessRequired
            children={null}
            fallback={<TalkToExpertCard />}
            feature={FEATURE_FLAGS.SCORECARD_RECOMMENDATIONS}
          />
        </AccessRequired>
      </div>
    </SidebarWrapper>
  );
};
