import { Outlet } from "react-router-dom";

import { useMarketplaceRegistrations } from "@mc/features/Onboarding";
import { findOnboarding } from "@mc/features/Onboarding/utils";

import { OnboardingPathRedirect } from "../redirects";

/**
 * Redirects to the current onboarding path if the user does
 * not have an ongoing onboarding for the selected product.
 * Otherwise renders child routes.
 */
export const OnboardingProductOutlet = ({ sku }: { sku: string }) => {
  const { pendingOnboardings } = useMarketplaceRegistrations();
  const pendingOnboarding = findOnboarding(pendingOnboardings, sku);

  if (!pendingOnboarding) return <OnboardingPathRedirect />;

  return <Outlet />;
};
