import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { PropsWithChildren, useState } from "react";

import {
  AccessRequired,
  AccessRequirements,
} from "@mc/components/AccessRequired";
import { useMatch } from "@mc/router";

interface Props extends PropsWithChildren<AccessRequirements> {
  closable?: boolean;
  defaultOpen?: boolean;
  path?: string;
  title?: string;
}

export const SidebarGroup = ({
  children,
  closable = false,
  defaultOpen = true,
  path = "",
  title,
  ...props
}: Props) => {
  const match = useMatch(path);
  const [open, setOpen] = useState<boolean>(
    closable ? defaultOpen || match !== null : true
  );

  const toggleMenu = () => {
    if (closable) setOpen((prev) => !prev);
  };
  const ChevronIcon = open ? ChevronDownIcon : ChevronLeftIcon;
  const TitleElement = closable ? "button" : "div";
  return (
    <AccessRequired {...props}>
      <div aria-label={title && `${title} Navigation Group`}>
        {title && (
          <TitleElement
            className={clsx(
              "mb-0.5 flex w-full items-center px-1 text-left text-sm font-semibold tracking-wider text-subdued",
              {
                "cursor-pointer": closable,
              }
            )}
            onClick={toggleMenu}
          >
            <span className="grow">{title}</span>
            {closable && <ChevronIcon className="inline h-2 w-2" />}
          </TitleElement>
        )}
        {open && children}
      </div>
    </AccessRequired>
  );
};
