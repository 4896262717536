import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { Button } from "@m/ui";

import { useCompleteCloudEngagementsOnboarding } from "../api";

export const CloudEngagementsOnboardingWelcomeDetails = () => {
  const [completeOnboarding] = useCompleteCloudEngagementsOnboarding();

  return (
    <Button kind="primary" className="w-full py-2" onClick={completeOnboarding}>
      Go to Mission Cloud Engagements - DevOps
      <ArrowRightIcon className="h-2 w-2" />
    </Button>
  );
};
