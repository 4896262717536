import { generateMockId } from "@m/api/testing";
import { dt } from "@m/utils";

import { ENGAGEMENT_SOURCE_TYPE, ENGAGEMENT_STATUS } from "../constants";
import {
  IBudgetStatus,
  IEngagement,
  IEngagementMilestoneItem,
  IEngagementSource,
  IRiskLogItem,
  IWorkStatus,
} from "../types";

import { EXAMPLE_MILESTONES_ITEMS } from "./milestones.examples";
import {
  EXAMPLE_ENGAGEMENT_BUDGET_OVER_COST,
  EXAMPLE_ENGAGEMENT_BUDGET_STATUS,
  EXAMPLE_ENGAGEMENT_BUDGET_UNDER_COST,
  EXAMPLE_ENGAGEMENT_WORK_STATUS,
  EXAMPLE_ENGAGEMENT_WORK_STATUS_AHEAD_OF_SCHEDULE,
  EXAMPLE_ENGAGEMENT_WORK_STATUS_BEHIND_SCHEDULE,
  EXAMPLE_ENGAGEMENT_WORK_STATUS_COMPLETED,
} from "./status.examples";

class ExampleEngagement {
  companyId: number;
  createTime: string;
  __typename: string;
  id: string;
  milestones: IEngagementMilestoneItem[];
  riskLogItems: IRiskLogItem[];
  source: IEngagementSource;
  sourceActive: boolean;
  sourceId: string;
  sourceType: ENGAGEMENT_SOURCE_TYPE;
  title: string;
  updateTime: string;
  visible: boolean;
  budgetStatus: IBudgetStatus;
  workStatus: IWorkStatus;
  engagementType: string;
  poolHours: number;

  constructor({
    id,
    milestones,
    title,
    budgetStatus,
    workStatus,
    visible = false,
    sourceId = generateMockId(),
    source,
    engagementType = "OTHER",
    updateTime,
    poolHours,
  }: Partial<IEngagement>) {
    this.__typename = "Engagement";
    this.id = id;
    this.sourceId = sourceId || generateMockId();
    this.title = title;
    this.source = source || { status: ENGAGEMENT_STATUS.IN_PROGRESS, title };
    this.visible = visible || false;
    this.budgetStatus = budgetStatus || EXAMPLE_ENGAGEMENT_BUDGET_STATUS;
    this.workStatus = workStatus || EXAMPLE_ENGAGEMENT_WORK_STATUS;
    this.milestones = milestones || [];
    this.engagementType = engagementType;
    this.updateTime = updateTime || dt.utc().toISO();
    this.poolHours = poolHours || 60;
  }
}

export const EXAMPLE_ENGAGEMENT_VISIBLE = new ExampleEngagement({
  title: "Large MAP Assess (PS)",
  visible: true,
  sourceId: "ABC123",
  id: "ABC123",
  source: {
    title: "Large MAP Assess (PS)",
    status: ENGAGEMENT_STATUS.IN_PROGRESS,
  },
  workStatus: EXAMPLE_ENGAGEMENT_WORK_STATUS_BEHIND_SCHEDULE,
  budgetStatus: EXAMPLE_ENGAGEMENT_BUDGET_STATUS,
  milestones: EXAMPLE_MILESTONES_ITEMS,
  engagementType: "ASSESS",
  updateTime: dt.utc().toISO(),
});

export const EXAMPLE_ENGAGEMENT_HIDDEN = new ExampleEngagement({
  title: "APP Dev Support (PS)",
  sourceId: "DEF456",
  id: "DEF456",
  workStatus: EXAMPLE_ENGAGEMENT_WORK_STATUS_AHEAD_OF_SCHEDULE,
  budgetStatus: EXAMPLE_ENGAGEMENT_BUDGET_OVER_COST,
  source: {
    title: "APP Dev Support (PS)",
    status: ENGAGEMENT_STATUS.IN_PROGRESS,
  },
  engagementType: "MOBILIZE",
  updateTime: dt.utc().toISO(),
});

export const EXAMPLE_UNTITLED_ENGAGEMENT = new ExampleEngagement({
  title: "",
  sourceId: "JKL012",
  id: "JKL012",
  source: {
    title: "",
    status: ENGAGEMENT_STATUS.IN_PROGRESS,
  },
  updateTime: dt.utc().toISO(),
});

export const EXAMPLE_LONG_ENGAGEMENT_TITLE = new ExampleEngagement({
  title: "Very Long Title Name That Exceeds 28 Characters",
  visible: true,
  sourceId: "MNO345",
  id: "MNO345",
  budgetStatus: EXAMPLE_ENGAGEMENT_BUDGET_UNDER_COST,
  source: {
    title: "Very Long Title Name That Exceeds 28 Characters",
    status: ENGAGEMENT_STATUS.IN_PROGRESS,
  },
  updateTime: dt.utc().minus({ week: 1 }).toISO(),
});

export const EXAMPLE_ENGAGEMENT = new ExampleEngagement({
  id: "GHI789",
  title: "Migrate LapDog LLC to AWS",
  visible: true,
  sourceId: "GHI789",
  source: {
    title: "Migrate LapDog LLC to AWS",
    status: ENGAGEMENT_STATUS.IN_PROGRESS,
  },
  engagementType: "MOBILIZE",
  updateTime: dt.utc().minus({ days: 3 }).toISO(),
});

export const EXAMPLE_ENGAGEMENT_COMPLETED = new ExampleEngagement({
  title: "Completed Engagement",
  visible: true,
  sourceId: "ABC313",
  id: "ABC313",
  source: {
    title: "Completed Engagement",
    status: ENGAGEMENT_STATUS.COMPLETED,
  },
  workStatus: EXAMPLE_ENGAGEMENT_WORK_STATUS_COMPLETED,
  budgetStatus: EXAMPLE_ENGAGEMENT_BUDGET_STATUS,
  milestones: EXAMPLE_MILESTONES_ITEMS,
  engagementType: "ASSESS",
  updateTime: dt.utc().minus({ hours: 10 }).toISO(),
});

export const EXAMPLE_ENGAGEMENT_AHEAD_OF_SCHEDULE_UNDER_BUDGET =
  new ExampleEngagement({
    title: "Ahead of Schedule, Under Budget Project",
    visible: true,
    sourceId: "248",
    id: "248",
    source: {
      title: "Ahead of Schedule, Under Budget Project",
      status: ENGAGEMENT_STATUS.IN_PROGRESS,
    },
    workStatus: EXAMPLE_ENGAGEMENT_WORK_STATUS_AHEAD_OF_SCHEDULE,
    budgetStatus: EXAMPLE_ENGAGEMENT_BUDGET_UNDER_COST,
    milestones: EXAMPLE_MILESTONES_ITEMS,
    engagementType: "ASSESS",
    updateTime: dt.utc().toISO(),
  });

export const EXAMPLE_ENGAGEMENT_BEHIND_SCHEDULE_OVER_BUDGET =
  new ExampleEngagement({
    title: "Behind Schedule, Over Budget Project",
    visible: true,
    sourceId: "7324",
    id: "7324",
    source: {
      title: "Behind Schedule, Over Budget Project",
      status: ENGAGEMENT_STATUS.IN_PROGRESS,
    },
    workStatus: EXAMPLE_ENGAGEMENT_WORK_STATUS_BEHIND_SCHEDULE,
    budgetStatus: EXAMPLE_ENGAGEMENT_BUDGET_OVER_COST,
    milestones: EXAMPLE_MILESTONES_ITEMS,
    engagementType: "ASSESS",
    updateTime: dt.utc().toISO(),
  });

export const EXAMPLE_ENGAGEMENT_ELEVATE = new ExampleEngagement({
  id: "GHI790",
  title: "Cloud Elevate",
  visible: true,
  sourceId: "GHI790",
  source: {
    title: "Migrate LapDog LLC to AWS",
    status: ENGAGEMENT_STATUS.IN_PROGRESS,
  },
  engagementType: "ELEVATE",
  updateTime: dt.utc().minus({ days: 3 }).toISO(),
});

export const EXAMPLE_ENGAGEMENTS_LIST = [
  EXAMPLE_ENGAGEMENT_VISIBLE,
  EXAMPLE_ENGAGEMENT_HIDDEN,
  EXAMPLE_UNTITLED_ENGAGEMENT,
  EXAMPLE_LONG_ENGAGEMENT_TITLE,
  EXAMPLE_ENGAGEMENT,
  EXAMPLE_ENGAGEMENT_AHEAD_OF_SCHEDULE_UNDER_BUDGET,
  EXAMPLE_ENGAGEMENT_BEHIND_SCHEDULE_OVER_BUDGET,
  EXAMPLE_ENGAGEMENT_COMPLETED,
];

export const VISIBLE_ENGAGEMENTS_LIST = [
  EXAMPLE_ENGAGEMENT_VISIBLE,
  EXAMPLE_ENGAGEMENT,
  EXAMPLE_LONG_ENGAGEMENT_TITLE,
  EXAMPLE_ENGAGEMENT_COMPLETED,
  EXAMPLE_ENGAGEMENT_AHEAD_OF_SCHEDULE_UNDER_BUDGET,
  EXAMPLE_ENGAGEMENT_BEHIND_SCHEDULE_OVER_BUDGET,
];

export const HIDDEN_ENGAGEMENTS_LIST = [EXAMPLE_ENGAGEMENT_HIDDEN];

export const EXAMPLE_ENGAGEMENT_ITEM_NOTES = [
  {
    id: "123",
    content: "this is a note",
    createdAt: dt.utc().toISO(),
    createdBy: {
      id: generateMockId(),
      email: "tom@tom.com",
      name: "Tom Tom",
      firstName: "Tom",
      lastName: "Tom",
    },
    updatedAt: dt.utc().toISO(),
  },
  {
    id: "124",
    content: "this is another note",
    createdAt: dt.utc().toISO(),
    createdBy: {
      id: generateMockId(),
      email: "tom@tom.com",
      name: "Tom Tom",
      firstName: "Tom",
      lastName: "Tom",
    },
    updatedAt: dt.utc().toISO(),
  },
  {
    id: "125",
    content: "one more",
    createdAt: dt.utc().toISO(),
    createdBy: {
      id: generateMockId(),
      email: "tom@tom.com",
      name: "Tom Tom",
      firstName: "Tom",
      lastName: "Tom",
    },
    updatedAt: dt.utc().toISO(),
  },
];
