import { SUPPORTED_PRODUCT_INVITATION_SKUS } from "@mc/constants";

import { EligibleProductsForInvitationType } from "../providers";

/**
 * Filter eligible products for invitation to only those supported by Mission Control.
 */
export const filterSupportedProductsForInvitation = (
  productsForInvitation: EligibleProductsForInvitationType
) =>
  productsForInvitation.filter(({ sku }) =>
    SUPPORTED_PRODUCT_INVITATION_SKUS.includes(sku)
  );
