import { ProjectType, Workspace } from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";
import { dt } from "@m/utils";

class ExampleWorkspace implements Workspace {
  budget?: string;
  dueDate?: string;
  id: string;
  isAssociatedWithEngagement?: boolean;
  startDate?: string;
  title: string;
  projectType: ProjectType;

  constructor({
    budget,
    dueDate,
    id,
    isAssociatedWithEngagement,
    startDate,
    title,
    projectType,
  }) {
    this.id = id || generateMockId();
    this.budget = budget || "$10,000";
    this.dueDate =
      dueDate === undefined
        ? dt.fromISO(this.startDate).plus({ week: 1 }).toUTC().toISO()
        : dueDate;
    this.isAssociatedWithEngagement = isAssociatedWithEngagement || false;
    this.startDate = startDate === undefined ? dt.utc().toISO() : startDate;
    this.title = title;
    this.projectType = projectType;
  }
}

export const EXAMPLE_WORKSPACE = new ExampleWorkspace({
  budget: "$79,500",
  dueDate: "2023-05-31T00:00:00+00:00",
  id: "123456789",
  isAssociatedWithEngagement: false,
  startDate: "2021-01-20T00:00:00+00:00",
  title: "Company Inc - Migration (PS)",
  projectType: "CLOUD_ELEVATE",
});

export const COMPLETED_EXAMPLE_WORKSPACE = new ExampleWorkspace({
  budget: "$30,400",
  dueDate: "2022-12-31T00:00:00+00:00",
  id: "987654321",
  isAssociatedWithEngagement: false,
  startDate: "2021-01-20T00:00:00+00:00",
  title: "Company Inc - Cloud Elevate",
  projectType: "CLOUD_ELEVATE",
});

export const EXAMPLE_WORKSPACE_NO_DETAILS = new ExampleWorkspace({
  budget: null,
  dueDate: null,
  id: "123123123",
  isAssociatedWithEngagement: false,
  startDate: null,
  title: "Company Inc - NiFi +Workspaces - PS",
  projectType: "CLOUD_ELEVATE",
});

export const EXAMPLE_WORKSPACES_LIST = [
  EXAMPLE_WORKSPACE,
  COMPLETED_EXAMPLE_WORKSPACE,
  EXAMPLE_WORKSPACE_NO_DETAILS,
];
