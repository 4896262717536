import { PATHS } from "@mc/constants";
import {
  useEligibleProductsForInvitation,
  useMarketplaceRegistrations,
} from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

/** Redirects to the current onboarding path. */
export const OnboardingPathRedirect = () => {
  const { hasProductsForInvitation } = useEligibleProductsForInvitation();
  const { hasPendingOnboardings } = useMarketplaceRegistrations();

  const currentPath = getCurrentOnboardingPath(
    hasProductsForInvitation,
    hasPendingOnboardings
  );

  return <Navigate replace to={currentPath} />;
};

const getCurrentOnboardingPath = (
  hasProductsForInvitation: boolean,
  hasPendingOnboardings: boolean
) => {
  if (hasProductsForInvitation) {
    return PATHS.ONBOARDING_PRODUCT_INVITE;
  }
  if (hasPendingOnboardings) {
    return PATHS.ONBOARDING_WELCOME;
  }
  return PATHS.ROOT;
};
