import { ReactNode } from "react";

interface Props {
  actions?: ReactNode;
  description?: ReactNode;
  heading: ReactNode;
}

export const PageHeading = ({
  heading,
  description,
  actions = null,
}: Props) => {
  return (
    <div className="mb-3 flex items-center justify-between">
      <div>
        <h3 className="text-base font-semibold">{heading}</h3>
        {description && (
          <div className="text-sm text-subdued">{description}</div>
        )}
      </div>
      {actions}
    </div>
  );
};
