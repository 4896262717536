import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";

import { MarketplaceSubscriptionCards } from "../components";

export const MarketplaceSubscriptionsPage = () => (
  <Page data-testid="marketplace-subscriptions-page">
    <PageHeading heading="Subscriptions" />
    <MarketplaceSubscriptionCards />
  </Page>
);
