import { Outlet, RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { StaffOnly } from "@mc/components/StaffOnly";
import { FEATURE_FLAGS } from "@mc/constants";
import { CloudScoreIneligiblePage } from "@mc/features/CloudScore";
import { AccessDenied, NotFound } from "@mc/pages";

import { cloudScoreRoutes } from "./cloudScoreRoutes";
import { dashboardRoutes } from "./dashboardRoutes";
import { engagementsRoutes } from "./engagementsRoutes";
import { CloudScoreOutlet } from "./outlets";
import { DefaultPathRedirect } from "./redirects";
import { resourcesRoutes } from "./resourcesRoutes";
import { scorecardRoutes } from "./scorecardRoutes";
import { settingsRoutes } from "./settingsRoutes";
import { staffRoutes } from "./staffRoutes";
import { supportRoutes } from "./supportRoutes";

export const defaultRoutes: RouteObject[] = [
  {
    index: true,
    element: <DefaultPathRedirect />,
  },
  {
    path: "cloud-score",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.SCORECARDS}
        fallback={<CloudScoreIneligiblePage />}
      >
        <CloudScoreOutlet />
      </AccessRequired>
    ),
    children: cloudScoreRoutes,
  },
  {
    path: "dashboard",
    element: (
      <AccessRequired feature={FEATURE_FLAGS.DASHBOARD} fallback={<NotFound />}>
        <Outlet />
      </AccessRequired>
    ),
    children: dashboardRoutes,
  },
  {
    path: "engagements",
    children: engagementsRoutes,
  },
  {
    path: "resources",
    element: (
      <AccessRequired feature={FEATURE_FLAGS.RESOURCES} fallback={<NotFound />}>
        <Outlet />
      </AccessRequired>
    ),
    children: resourcesRoutes,
  },
  { path: "scorecard", children: scorecardRoutes },
  { path: "settings", children: settingsRoutes },
  {
    path: "staff",
    element: (
      <StaffOnly fallback={<NotFound />}>
        <AccessRequired
          feature={FEATURE_FLAGS.MISSION_CONTROL_STAFF_LOUNGE}
          fallback={<AccessDenied />}
        >
          <Outlet />
        </AccessRequired>
      </StaffOnly>
    ),
    children: staffRoutes,
  },
  {
    path: "support",
    element: (
      <AccessRequired feature={FEATURE_FLAGS.CASES} fallback={<NotFound />}>
        <Outlet />
      </AccessRequired>
    ),
    children: supportRoutes,
  },
  { path: "*", element: <NotFound /> },
];
