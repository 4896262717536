import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { FullScreenLayout } from "@mc/layouts";

import { DefaultPathRedirect } from "../redirects";

/**
 * Renders child routes, if the user is not logged in,
 * otherwise redirects to root.
 */
export const UnauthenticatedOutlet = () => {
  const { user } = useAuth();

  if (user) return <DefaultPathRedirect />;

  return (
    <FullScreenLayout>
      <Outlet />
    </FullScreenLayout>
  );
};
