import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Engagement } from "@m/api/public/types";
import { Spinner, useModalState } from "@m/ui";

import { HTMLContent } from "@mc/components/HTMLContent";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { DetailsLayout } from "@mc/layouts";

import { useEngagementDetails } from "../api";
import {
  EngagementDetailsLists,
  EngagementDetailsPageHeader,
  EngagementSettingsModal,
  EngagementsPageTitleActions,
  EngagementsPoolHoursWidget,
} from "../components";

export const EngagementsElevateProjectPage = () => {
  const { engagementId } = useParams<{
    engagementId: string;
  }>();
  const [shouldShowAllItems, setShouldShowAllItems] = useState(false);
  const engagementSettingsModalState = useModalState();
  const location = useLocation();

  const {
    data: { engagementDetails },
    loading: engagementDetailsLoading,
  } = useEngagementDetails(engagementId);

  const handleShowAllItems = () => setShouldShowAllItems((prev) => !prev);

  const isLoading = engagementDetailsLoading;
  const isMarketplacePath = location.pathname.includes(
    PATHS.CLOUD_ENGAGEMENTS_PROJECTS_DETAILS
  );

  return (
    <>
      <Page
        data-testid="cloud-engagements-dashboard"
        width="full"
        padding={false}
        title={
          isMarketplacePath ? (
            <>
              <PageTitle
                padding="small"
                breadcrumbs={[
                  {
                    path: PATHS.ENGAGEMENTS_PROJECTS,
                    name: "Projects",
                  },
                  {
                    path: PATHS.ENGAGEMENTS_PROJECTS_DETAILS,
                    name: "Dashboard",
                  },
                ]}
                actions={
                  <EngagementsPageTitleActions
                    loading={isLoading}
                    shouldShowAllItems={shouldShowAllItems}
                    onShowAllItems={handleShowAllItems}
                    onEditClick={engagementSettingsModalState.open}
                  />
                }
              />
              {!isLoading && (
                <EngagementSettingsModal
                  engagement={engagementDetails}
                  isModalOpen={engagementSettingsModalState.isOpen}
                  onModalClose={engagementSettingsModalState.close}
                />
              )}
            </>
          ) : (
            <EngagementDetailsPageHeader
              engagementDetails={engagementDetails || {}}
              loading={isLoading}
              shouldShowAllItems={shouldShowAllItems}
              setShouldShowAllItems={setShouldShowAllItems}
              engagementSettingsModalState={engagementSettingsModalState}
            />
          )
        }
      >
        <DetailsLayout
          actions={
            <SideBar
              engagementDetails={engagementDetails}
              isLoading={isLoading}
            />
          }
        >
          <div className="flex flex-col gap-5">
            <div data-testid="engagement-details-header">
              <HTMLContent className="text-2xl font-semibold">
                {engagementDetails?.title || ""}
              </HTMLContent>
            </div>

            <EngagementsPoolHoursWidget
              poolHours={engagementDetails?.poolHours}
              onEditClick={engagementSettingsModalState.open}
              isLoading={isLoading}
            />

            <EngagementDetailsLists
              engagementId={engagementId}
              shouldShowAllItems={shouldShowAllItems}
            />
          </div>
        </DetailsLayout>

        {/* See comment at L107
        
        {inviteModalState.isOpen && (
          <InviteUsersModal
            open={inviteModalState.isOpen}
            onClose={inviteModalState.close}
            users={unregisteredUsers}
          />
        )} */}
      </Page>
    </>
  );
};

const SideBar = ({
  engagementDetails,
  isLoading,
}: {
  engagementDetails: Engagement;
  isLoading: boolean;
}) => {
  // Eventually, we'll want to include a list of team members associated with a project in the sidebar.
  // This logic and the rest of the commented out code will be used and updated at that time.
  //
  // const listItems = useMemo(() => {
  //   return Array.from(missionTeamMembers)
  //     .sort((a, b) => sortNameAsc(a.account, b.account))
  //     .map(({ account, jobRole }) => (
  //       <TeamMember key={account.id} account={account} jobRole={jobRole} />
  //     ));
  // }, [missionTeamMembers]);
  // const teamMembersEmpty = !isLoading && (missionTeamMembers.length < 1 || error);
  // const handleInviteClick = () => inviteModalState.open();

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col items-center">
          <Spinner />
        </div>
      ) : (
        <>
          {/* TODO(jamesmoody): Remove placeholders in sidebar after wire-up */}
          <div
            className="flex justify-between text-xs"
            data-testid="sidebar-pool-hours"
          >
            <span className="font-semibold">Pool Hours</span>
            <span className="text-subdued">
              {engagementDetails.poolHours || 0} Hours
            </span>
          </div>
          <div
            className="flex justify-between text-xs"
            data-testid="sidebar-sprint-cadence"
          >
            <span className="font-semibold">Sprint Cadence</span>
            <span className="text-subdued">Weekly</span>
          </div>
          <div
            className="flex justify-between text-xs"
            data-testid="sidebar-subscribed-date"
          >
            <span className="font-semibold">Subscribed</span>
            <span className="cursor-help border-b-[1px] border-dashed text-subdued">
              4 months ago
            </span>
          </div>
        </>
      )}

      <hr />

      {/* See comment at L107
      <div className="flex flex-col gap-1">
        <span className="text-xs font-semibold">Mission Cloud</span>
        {isLoading && <Spinner className="m-auto" />}
        {!isLoading && !teamMembersEmpty && listItems}
        {teamMembersEmpty && "-"}
      </div> */}
      {/* <div className="flex flex-col gap-1">
        <div className="flex justify-between text-xs">
          <span className="font-semibold">LapDog LLC</span>
          <Link onClick={handleInviteClick}>Invite</Link>
        </div>
        <TeamMember account={user} />
      </div> */}
    </>
  );
};

// See comment at L107
// const TeamMember = ({ account, jobRole }: TeamMemberType) => {
//   const { firstName, lastName, email } = account;
//   const teamMemberName = email?.includes("@missioncloud.com")
//     ? abbreviateLastName(firstName, lastName)
//     : `${firstName} ${lastName}`;

//   return (
//     <div className="inline-flex items-center text-xs">
//       <Avatar account={account} className="mr-1 text-xs font-semibold" />
//       <div>
//         <span className="font-semibold">{teamMemberName}</span>
//         {jobRole?.name && (
//           <div aria-label="Team Member Role" className="text-xs text-subdued">
//             {jobRole.name}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
