import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { ConnectCloudScoreAccountPage } from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

export const onboardingCloudScoreRoutes: RouteObject[] = [
  {
    path: "account",
    element: <ConnectCloudScoreAccountPage />,
  },
  {
    path: "*",
    element: <Navigate replace to={PATHS.ONBOARDING_WELCOME} />,
  },
];
