import { Outlet, RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, MUTATIONS, PATHS, SKUS } from "@mc/constants";
import {
  CreateDevOpsDeskPage,
  DevOpsDeskListPage,
} from "@mc/features/DevOpsDesk/pages";
import { DevOpsRequestDetailsPage } from "@mc/features/DevOpsDesk/pages/DevOpsRequestDetailsPage";
import {
  EngagementsConfigurationPage,
  EngagementsDetailsPage,
  EngagementsElevateProjectPage,
  EngagementsPage,
} from "@mc/features/Engagements";
import { NotFound } from "@mc/pages";
import { Navigate } from "@mc/router";

import { engagementModalRoutes } from "./engagementModalRoutes";

export const engagementsRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.ENGAGEMENTS}
        fallback={<NotFound />}
      >
        <Navigate replace to={PATHS.ENGAGEMENTS_PROJECTS} />
      </AccessRequired>
    ),
  },
  {
    path: "projects",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.ENGAGEMENTS}
        fallback={<NotFound />}
      >
        <EngagementsPage />
      </AccessRequired>
    ),
  },
  {
    path: "projects/configuration",
    element: (
      <AccessRequired
        mutation={MUTATIONS.CREATE_ENGAGEMENT_WITH_TEMPLATE}
        fallback={<Navigate replace to={PATHS.ENGAGEMENTS_PROJECTS} />}
      >
        <EngagementsConfigurationPage />
      </AccessRequired>
    ),
  },
  {
    path: "projects/:engagementId",
    element: (
      <AccessRequired
        entitlement={SKUS.CLOUD_ENGAGEMENTS}
        fallback={
          <AccessRequired
            feature={FEATURE_FLAGS.ENGAGEMENTS}
            fallback={<NotFound />}
          >
            <EngagementsDetailsPage />
            <Outlet />
          </AccessRequired>
        }
      >
        <EngagementsElevateProjectPage />
        <Outlet />
      </AccessRequired>
    ),
    children: engagementModalRoutes,
  },
  {
    path: "engineer-assist",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.DEVOPS_DESK}
        fallback={<NotFound />}
      >
        <DevOpsDeskListPage />
      </AccessRequired>
    ),
  },
  {
    path: "engineer-assist/create",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.DEVOPS_DESK}
        fallback={<NotFound />}
      >
        <CreateDevOpsDeskPage />
      </AccessRequired>
    ),
  },
  {
    path: "engineer-assist/:sysId",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.DEVOPS_DESK}
        fallback={<NotFound />}
      >
        <DevOpsRequestDetailsPage />
      </AccessRequired>
    ),
  },
];
